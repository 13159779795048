import { get } from "lodash";

import {
  IMPORT_PRODUCT_REQ,
  IMPORT_PRODUCT_RES,
  IMPORT_PRODUCT_ERR,
  FETCH_PRODUCTS_REQ,
  FETCH_PRODUCTS_RES,
  FETCH_PRODUCTS_ERR,
  FETCH_IMPORTED_PRODUCTS_REQ,
  FETCH_IMPORTED_PRODUCTS_RES,
  FETCH_IMPORTED_PRODUCTS_ERR,
  DELETE_PRODUCT_RES,
  DELETE_PRODUCT_REQ,
  DELETE_PRODUCT_ERR,
  UPDATE_PRODUCT_FROM_LS,
  UPDATE_PRODUCTS_FROM_LS,
  ADD_TO_IMPORT_LIST_REQ,
  ADD_TO_IMPORT_LIST_RES,
  ADD_TO_IMPORT_LIST_ERR,
  UPDATE_IMPORT_LIST_ITEM_REQ,
  UPDATE_IMPORT_LIST_ITEM_RES,
  UPDATE_IMPORT_LIST_ITEM_ERR,
  TOGGLE_SELECT_IMPORT_LIST_ITEM_REQ,
  TOGGLE_SELECT_IMPORT_LIST_REQ,
  SET_IMPORT_LIST_ITEM_SAVED_RES,
  SET_LAST_PAGINATION_STATE,
  GENERIC_REQ,
  GENERIC_RES,
  GENERIC_ERR as PRODUCTS_GENERIC_ERR,
  FETCH_SUPPLIER_REQ,
  FETCH_SUPPLIER_RES,
  FETCH_SUPPLIER_ERR,
  FETCH_SUPPLIERS_REQ,
  FETCH_SUPPLIERS_RES,
  FETCH_SUPPLIERS_ERR,
  REQUEST_APPROVAL_REQ,
  REQUEST_APPROVAL_RES,
  REQUEST_APPROVAL_ERR,
  REMOVE_FROM_IMPORT_LIST_REQ,
  REMOVE_FROM_IMPORT_LIST_RES,
  REMOVE_FROM_IMPORT_LIST_ERR,
  FETCH_IMPORT_LIST_REQ,
  FETCH_IMPORT_LIST_RES,
  FETCH_IMPORT_LIST_ERR,
  FETCH_IMPORT_LIST_PRODUCT_REQ,
  FETCH_IMPORT_LIST_PRODUCT_RES,
  FETCH_IMPORT_LIST_PRODUCT_ERR,
  DELETE_SUPPLIER_PRODUCTS_REQ,
  DELETE_SUPPLIER_PRODUCTS_RES,
  DELETE_SUPPLIER_PRODUCTS_ERR,
  MARK_FEATURED_REQ,
  MARK_FEATURED_RES,
  MARK_FEATURED_ERR,
  ADD_PRODUCT_TO_CART_REQ,
  ADD_PRODUCT_TO_CART_RES,
  ADD_PRODUCT_TO_CART_ERR,
  ADD_VARIANT_TO_CART_REQ,
  ADD_VARIANT_TO_CART_RES,
  ADD_VARIANT_TO_CART_ERR,
  FETCH_ALL_STORE_PRODUCTS_RES,
  FETCH_NOTIFICATIONS_RES,
  SELECT_VARIANT_OPTION,
  FETCH_UNSYNCED_PRODUCTS_RES,
  FIX_UNSYNCED_PRODUCT_REQ,
  FIX_UNSYNCED_PRODUCT_RES,
  FIX_UNSYNCED_PRODUCT_ERR,
  CREATE_REVIEW_RES,
  CREATE_REVIEW_REQ,
  CREATE_REVIEW_ERR,
  CREATE_REFUND_RES,
  CREATE_REFUND_REQ,
  CREATE_REFUND_ERR,
  FETCH_DASHBOARD_DATA_REQ,
  FETCH_DASHBOARD_DATA_RES,
  FETCH_DASHBOARD_DATA_ERR,
} from "../../views/products/ProductsActions";

import { GENERIC_ERR, RESET_PRODUCTS } from "../../Actions";

const initialState = {
  errors: {},
  product: {},
  products: [],
  unsyncedProducts: [],
  importList: [],
  importedProducts: [],
  storeCategories: [],
  isFetching: 0,
  isFetchingImportedProducts: 0,
  dashboardData: {},
  pagination: {},
  productTags: [],
  pageImported: false,
  notifications: [],
  activeRequest: null,
  isFetchingProducts: false,
  isFetchingReview: 0,
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case "products/CLEAR_ERRORS":
      return { ...state, errors: {}, error: null };

    case SELECT_VARIANT_OPTION:
      return {
        ...state,
        product: {
          ...state.product,
          variants: state.product.variants.map((variant) => {
            if (variant.id === action.payload.variantId) {
              return {
                ...variant,
                active: !variant.active,
              };
            } else {
              return {
                ...variant,
                active: false,
              };
            }
          }),
        },
      };

    case FETCH_ALL_STORE_PRODUCTS_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        allStoreProducts: action.payload.products,
      };

    case ADD_PRODUCT_TO_CART_REQ:
      return {
        ...state,
        product: { ...state.product, isFetching: true },
      };

    case ADD_PRODUCT_TO_CART_RES:
      return {
        ...state,
        product: {
          ...state.product,
          isFetching: false,
          in_sample_cart: true,
        },
      };

    case ADD_PRODUCT_TO_CART_ERR:
      return {
        ...state,
        product: { ...state.product, isFetching: false },
      };

    case ADD_VARIANT_TO_CART_REQ:
      return {
        ...state,
        product: {
          ...state.product,
          variants: state.product.variants.map((variant) => {
            if (variant.id === action.payload.variantId) {
              return { ...variant, isFetching: true };
            }
            return variant;
          }),
        },
      };

    case ADD_VARIANT_TO_CART_RES:
      return {
        ...state,
        product: {
          ...state.product,
          variants: state.product.variants.map((variant) => {
            if (variant.id === action.payload.variantId) {
              return { ...variant, isFetching: false, in_sample_cart: true };
            }
            return variant;
          }),
        },
      };

    case ADD_VARIANT_TO_CART_ERR:
      return {
        ...state,
        product: {
          ...state.product,
          variants: state.product.variants.map((variant) => {
            if (variant.id === action.payload.variantId) {
              return { ...variant, isFetching: false };
            }
            return variant;
          }),
        },
      };

    case FETCH_IMPORT_LIST_PRODUCT_REQ:
      return {
        ...state,
        importList: state.importList.map((product) => {
          if (product.imported_product_id === action.payload.product_id) {
            return { ...product, isFetching: true };
          }
          return product;
        }),
      };

    case FETCH_IMPORT_LIST_PRODUCT_RES:
      return {
        ...state,
        importList: state.importList.map((product) => {
          if (product.imported_product_id === action.payload.product_id) {
            return {
              ...action.payload.product,
              isFetching: false,
              expanded: true,
              imported: product.imported,
            };
          }
          return product;
        }),
      };

    case FETCH_IMPORT_LIST_PRODUCT_ERR:
      return {
        ...state,
        importList: state.importList.map((product) => {
          if (product.imported_product_id === action.payload.product_id) {
            return {
              ...product,
              isFetching: false,
              error: "Error fetching product data. Contact support.",
            };
          }
          return product;
        }),
      };

    case REQUEST_APPROVAL_ERR:
      return {
        ...state,
        error: action.payload.error,
        supplier: {
          ...state.supplier,
          isFetching: false,
          approval_status: "R",
        },
        product: {
          ...state.product,
          supplier: { ...state.product.supplier, approval_status: "R" },
          isFetching: false,
        },
        products: state.products.map((product) => {
          if (product.supplier.id === action.payload.supplier_id) {
            return {
              ...product,
              isFetching: false,
              supplier: { ...product.supplier, approval_status: "R" },
            };
          } else {
            return product;
          }
        }),
      };

    case REQUEST_APPROVAL_RES: {
      let approvalStatus = action.payload.cancel ? "R" : "P";

      return {
        ...state,
        supplier: {
          ...state.supplier,
          isFetching: false,
          approval_status: approvalStatus,
        },
        product: {
          ...state.product,
          supplier: {
            ...state.product.supplier,
            approval_status: approvalStatus,
          },
          isFetching: false,
        },
        products: state.products.map((product) => {
          if (product.supplier.id === action.payload.supplier_id) {
            return {
              ...product,
              isFetching: false,
              supplier: {
                ...product.supplier,
                approval_status: approvalStatus,
              },
            };
          } else {
            return product;
          }
        }),
      };
    }

    case REQUEST_APPROVAL_REQ:
      return {
        ...state,
        supplier: { ...state.supplier, isFetching: true },
        product: { ...state.product, isFetching: true },
        products: state.products.map((product) => {
          if (product.supplier.id === action.payload.supplier_id) {
            return { ...product, isFetching: true };
          } else {
            return product;
          }
        }),
      };

    case DELETE_SUPPLIER_PRODUCTS_REQ:
      return {
        ...state,
        suppliers: state.suppliers.map((supplier) => {
          if (supplier.id === action.payload.supplierId) {
            return { ...supplier, isFetching: true };
          }
          return supplier;
        }),
      };

    case DELETE_SUPPLIER_PRODUCTS_ERR:
      return {
        ...state,
        suppliers: state.suppliers.map((supplier) => {
          if (supplier.id === action.payload.supplierId) {
            return { ...supplier, isFetching: false };
          }
          return supplier;
        }),
      };

    case DELETE_SUPPLIER_PRODUCTS_RES:
      return {
        ...state,
        suppliers: state.suppliers.map((supplier) => {
          if (supplier.id === action.payload.supplierId) {
            return { ...supplier, productsDeleted: true, isFetching: false };
          }
          return supplier;
        }),
      };

    case FETCH_SUPPLIERS_REQ:
      return {
        ...state,
        suppliers: [],
        pagination: {},
        isFetchingSuppliers: true,
      };

    case FETCH_SUPPLIERS_RES:
      return {
        ...state,
        suppliers: action.payload.suppliers,
        pagination: action.payload.pagination,
        isFetchingSuppliers: false,
      };

    case FETCH_SUPPLIERS_ERR:
      return {
        ...state,
        suppliers: [],
        pagination: {},
        isFetchingSuppliers: false,
      };

    case FETCH_SUPPLIER_REQ:
      return {
        ...state,
        supplier: {},
        products: [],
        pagination: {},
        isFetchingSupplier: true,
      };

    case FETCH_SUPPLIER_RES:
      return {
        ...state,
        supplier: action.payload.supplier,
        products: action.payload.supplier.products,
        pagination: action.payload.pagination,
        isFetchingSupplier: false,
        product: {},
      };

    case FETCH_SUPPLIER_ERR:
      return {
        ...state,
        isFetchingSupplier: false,
        error: action.payload.error,
        errorType: action.payload.errorType,
      };

    case CREATE_REVIEW_RES:
      return {
        ...state,
        supplier: { ...state.supplier, reviews: action.payload },
        isFetchingReview: state.isFetchingReview - 1,
      };

    case CREATE_REVIEW_REQ:
      return {
        ...state,
        isFetchingReview: state.isFetchingReview + 1,
      };

    case CREATE_REVIEW_ERR:
      return {
        ...state,
        isFetchingReview: state.isFetchingReview - 1,
        error: action.payload.error,
      };

    case CREATE_REFUND_RES:
      return {
        ...state,
        supplier: { ...state.supplier, refunds: action.payload },
        isFetchingRefund: state.isFetchingRefund - 1,
      };

    case CREATE_REFUND_REQ:
      return {
        ...state,
        isFetchingRefund: state.isFetchingRefund + 1,
      };

    case CREATE_REFUND_ERR:
      return {
        ...state,
        isFetchingRefund: state.isFetchingRefund - 1,
        error: action.payload.error,
      };

    case GENERIC_REQ:
      return {
        ...state,
        isFetching: state.isFetching + 1,
      };

    case GENERIC_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
      };

    case SET_LAST_PAGINATION_STATE:
      return {
        ...state,
        lastPagination: state.pagination,
      };

    case IMPORT_PRODUCT_REQ:
      return {
        ...state,
        importList: state.importList.map((product) => {
          if (
            action.payload.importedProductIds.includes(
              product.imported_product_id
            )
          ) {
            return {
              ...product,
              isFetching: true,
              imported: true,
              error: null,
              selected: false,
            };
          } else {
            return product;
          }
        }),
      };

    case IMPORT_PRODUCT_ERR: {
      const importLimit = get(action.payload.error, "code") === "IMPORT_LIMIT";

      return {
        ...state,
        error: action.payload.error,
        errors: action.payload.errors,
        importList: state.importList.map((product) => {
          if (
            action.payload.importedProductIds.includes(
              product.imported_product_id
            )
          ) {
            return {
              ...product,
              isFetching: false,
              imported: false,
              error: !importLimit
                ? "Error importing product. Please contact support@dropcommerce.com."
                : null,
            };
          } else {
            return product;
          }
        }),
      };
    }

    case UPDATE_IMPORT_LIST_ITEM_REQ:
      return {
        ...state,
        importList: state.importList.map((product) => {
          if (
            product.imported_product_id === action.payload.imported_product_id
          ) {
            return { ...product, isSaving: true };
          }
          return product;
        }),
      };

    case UPDATE_IMPORT_LIST_ITEM_RES:
      return {
        ...state,
        importList: state.importList.map((product) => {
          if (
            product.imported_product_id === action.payload.imported_product_id
          ) {
            return {
              ...product,
              isSaving: false,
              productSaved: true,
              expanded: false,
            };
          }
          return product;
        }),
      };

    case UPDATE_IMPORT_LIST_ITEM_ERR:
      return {
        ...state,
        importList: state.importList.map((product) => {
          if (
            product.imported_product_id === action.payload.imported_product_id
          ) {
            return { ...product, isSaving: false, error: action.payload.error };
          }
          return product;
        }),
      };

    case TOGGLE_SELECT_IMPORT_LIST_ITEM_REQ:
      return {
        ...state,
        importList: state.importList.map((product) => {
          if (
            product.imported_product_id === action.payload.imported_product_id
          ) {
            return { ...product, selected: !product.selected };
          }
          return product;
        }),
      };

    case TOGGLE_SELECT_IMPORT_LIST_REQ:
      return {
        ...state,
        importList: state.importList.map((product) => {
          return { ...product, selected: action.payload.selected };
        }),
      };

    case RESET_PRODUCTS:
      return {
        ...state,
        products: [],
      };

    case FETCH_DASHBOARD_DATA_REQ:
      return {
        ...state,
        dashboardData: {},
        isFetchingDashboard: true,
      };

    case FETCH_DASHBOARD_DATA_RES:
      return {
        ...state,
        products: action.payload.products,
        dashboardData: action.payload.data,
        isFetchingDashboard: false,
      };

    case FETCH_DASHBOARD_DATA_ERR:
      return {
        ...state,
        isFetchingDashboard: false,
      };

    case FETCH_PRODUCTS_REQ:
      return {
        ...state,
        isFetchingProducts: true,
        activeRequest: action.payload.request_id,
      };

    case REMOVE_FROM_IMPORT_LIST_REQ:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          count: state.pagination.count - 1,
          last_item: state.pagination.last_item - 1,
        },
        importList: state.importList.map((product) => {
          if (
            product.imported_product_id === action.payload.imported_product_id
          ) {
            return { ...product, isFetching: true };
          }
          return product;
        }),
      };

    case REMOVE_FROM_IMPORT_LIST_RES:
      return {
        ...state,
        importList: state.importList.filter(
          (product) =>
            product.imported_product_id !== action.payload.imported_product_id
        ),
      };

    case REMOVE_FROM_IMPORT_LIST_ERR:
      return {
        ...state,
        importList: state.importList.map((product) => {
          if (
            product.imported_product_id === action.payload.imported_product_id
          ) {
            return {
              ...product,
              isFetching: false,
              error: "Error removing item from import list",
            };
          }
          return product;
        }),
      };

    case "products/SHOW_ALL_CHANGES":
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification.product_id === action.payload.notificationId) {
            return { ...notification, showAll: true };
          }
          return notification;
        }),
      };

    case FETCH_NOTIFICATIONS_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        notifications: action.payload.notifications,
        pagination: action.payload.pagination,
      };

    case ADD_TO_IMPORT_LIST_REQ:
      return {
        ...state,
        product: { ...state.product, isFetching: true },
        products: state.products.map((product) => {
          if (product.id === action.payload.product) {
            return { ...product, isFetching: true };
          } else {
            return product;
          }
        }),
      };

    case SET_IMPORT_LIST_ITEM_SAVED_RES:
      return {
        ...state,
        importList: state.importList.map((product) => {
          if (
            product.import_info &&
            product.import_info.id === action.payload.item_id
          ) {
            return { ...product, productSaved: false };
          } else {
            return product;
          }
        }),
      };

    case ADD_TO_IMPORT_LIST_RES: {
      let newProducts = state.products.map((product) => {
        if (product.id === action.payload.product) {
          return {
            ...product,
            isFetching: false,
            imported: "list",
          };
        } else {
          return product;
        }
      });
      return {
        ...state,
        product: {
          ...state.product,
          isFetching: false,
          imported: "list",
        },
        products: newProducts,
      };
    }

    case ADD_TO_IMPORT_LIST_ERR:
      return {
        ...state,
        error: action.payload.error,
        product: {
          ...state.product,
          error: action.payload.error,
          isFetching: false,
        },
        products: state.products.map((product) => {
          if (product.id === action.payload.product) {
            return {
              ...product,
              isFetching: false,
              error: action.payload.error,
            };
          } else {
            return product;
          }
        }),
      };

    case IMPORT_PRODUCT_RES:
      return {
        ...state,
        importList: state.importList.map((product) => {
          if (
            action.payload.importedProductIds.includes(
              product.imported_product_id
            )
          ) {
            return {
              ...product,
              imported: true,
              isFetching: false,
            };
          } else {
            return product;
          }
        }),
      };

    case FETCH_IMPORTED_PRODUCTS_REQ:
      return {
        ...state,
        isFetchingImportedProducts: state.isFetching + 1,
      };

    case FETCH_IMPORTED_PRODUCTS_ERR:
      return {
        ...state,
        isFetchingImportedProducts: state.isFetching - 1,
      };

    case MARK_FEATURED_REQ:
      return {
        ...state,
        products: state.products.map((product) => {
          if (product.id === action.payload.productId) {
            return { ...product, isFetching: true };
          }
          return product;
        }),
      };

    case FIX_UNSYNCED_PRODUCT_REQ:
      return {
        ...state,
        unsyncedProducts: state.unsyncedProducts.map((product) => {
          if (product.bad_product === action.payload.productId) {
            return { ...product, isFetching: true };
          }
          return product;
        }),
      };

    case FIX_UNSYNCED_PRODUCT_RES:
      return {
        ...state,
        unsyncedProducts: state.unsyncedProducts.map((product) => {
          if (product.bad_product === action.payload.productId) {
            return {
              ...product,
              isFetching: false,
              fixed: action.payload.fixed,
              error: action.payload.error,
            };
          }
          return product;
        }),
      };

    case FIX_UNSYNCED_PRODUCT_ERR:
      return {
        ...state,
        unsyncedProducts: state.unsyncedProducts.map((product) => {
          if (product.id === action.payload.productId) {
            return {
              ...product,
              isFetching: true,
              error: action.payload.error,
            };
          }
          return product;
        }),
      };

    case FETCH_UNSYNCED_PRODUCTS_RES:
      return {
        ...state,
        unsyncedProducts: action.payload,
        isFetching: state.isFetching - 1,
      };

    case MARK_FEATURED_RES:
      return {
        ...state,
        products: state.products.map((product) => {
          if (product.id === action.payload.productId) {
            return { ...product, isFetching: false, featured: true };
          }
          return product;
        }),
      };

    case MARK_FEATURED_ERR:
      return {
        ...state,
        products: state.products.map((product) => {
          if (product.id === action.payload.productId) {
            return { ...product, isFetching: false };
          }
          return product;
        }),
      };

    case FETCH_IMPORTED_PRODUCTS_RES:
      return {
        ...state,
        warningCount: action.payload.warningCount,
        issueCount: action.payload.issueCount,
        importedProducts: action.payload.importedProducts,
        isFetchingImportedProducts: state.isFetching - 1,
        pagination: action.payload.pagination,
        product: {},
        supplier: {},
      };

    case FETCH_IMPORT_LIST_REQ:
      return {
        ...state,
        isFetchingImportList: true,
        importList: [],
        product: {},
        supplier: {},
        pagination: {},
      };

    case FETCH_IMPORT_LIST_RES:
      return {
        ...state,
        importList: action.payload.importList,
        productIds: action.payload.productIds,
        pagination: action.payload.pagination,
        storeCategories: action.payload.storeCategories,
        isFetchingImportList: false,
        pageImported: false,
      };

    case FETCH_IMPORT_LIST_ERR:
      return {
        ...state,
        isFetchingImportList: false,
        error: action.payload.error,
      };

    case FETCH_PRODUCTS_RES:
      if (action.payload.request_id === state.activeRequest) {
        return {
          ...state,
          products: action.payload.products,
          pagination: action.payload.pagination,
          isFetchingProducts: false,
        };
      }
      break; // Patrick, linter expected a 'break' statement before 'case' (no-fallthrough)

    case FETCH_PRODUCTS_ERR: {
      const { error } = action.payload;
      return { ...state, isFetchingProducts: false, error };
    }

    case GENERIC_ERR:
      return {
        ...state,
        error: action.payload.error,
        errorType: action.payload.errorType,
        isFetching: state.isFetching - 1,
      };

    case PRODUCTS_GENERIC_ERR:
      return {
        ...state,
        error: action.payload.error,
        errorType: action.payload.errorType,
        isFetching: state.isFetching - 1,
      };

    case DELETE_PRODUCT_RES:
      return {
        ...state,
        importedProducts: state.importedProducts.filter(
          (product) => product.id !== action.payload.product
        ),
      };

    case DELETE_PRODUCT_REQ:
      return {
        ...state,
        importedProducts: state.importedProducts.map((product) => {
          if (product.id === action.payload.product) {
            return {
              ...product,
              isFetching: true,
            };
          }
          return product;
        }),
      };

    case DELETE_PRODUCT_ERR:
      return {
        ...state,
        error: action.payload.error,
        isFetching: state.isFetching - 1,
      };

    case UPDATE_PRODUCTS_FROM_LS:
      return {
        ...state,
        products: action.payload.products,
        pagination: action.payload.pagination,
      };

    case UPDATE_PRODUCT_FROM_LS:
      return {
        ...state,
        product: action.payload.product,
      };

    default:
      return state;
  }
};

export default products;

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSettings } from "../../../Selectors";
import { ButtonNew } from "../../../components";
import { get } from "lodash";
import { removeFromImportList } from "../../../views/products/ProductsActions";
import { Modal } from "../../../components/Modal";
import PricingOptions from "../../../views/PricingOptions";
import createNotification from "../../../modules/Notification";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      removeFromImportList,
    },
    dispatch
  ),
});

const ButtonRow = styled.div`
  display: flex;
  @media (max-width: 800px) {
    width: 100%;
    flex-direction: row;
    margin-top: 15px;
  }
`;

const StyledButton = styled(ButtonNew)`
  ${(p) => p.marginRight && "margin-right: 15px;"}
  @media (max-width: 800px) {
    font-size: 12px;
    margin: 0px;
    ${(p) => p.marginRight && "margin-right: 5px;"}
    width: 100%;
  }
`;

class ProductButtons extends Component {
  state = { showPricingPlans: false };

  renderPricing = () => {
    if (this.state.showPricingPlans) {
      return (
        <Modal
          fullScreen
          hide={() => this.setState({ showPricingPlans: false })}
        >
          <PricingOptions
            hideModal={() => this.setState({ showPricingPlans: false })}
          />
        </Modal>
      );
    }
  };

  render() {
    const { product } = this.props;
    return (
      <ButtonRow>
        {this.renderPricing()}

        {!this.props.minimized && (
          <StyledButton
            text={product.productSaved ? "SAVED" : "SAVE"}
            type="secondary"
            buttonStyle={{ marginRight: "15px" }}
            onClick={() => this.props.saveProduct(false)}
            isFetching={product.isSaving}
            marginRight
          />
        )}

        {this.props.minimized && !product.imported && (
          <StyledButton
            text="EDIT"
            type="secondary"
            disabled={product.imported}
            marginRight
          />
        )}

        {!product.imported && (
          <StyledButton
            text="REMOVE"
            type="secondary"
            onClick={(e) =>
              this.props.removeFromList(e, product.imported_product_id)
            }
            disabled={product.imported}
            marginRight
          />
        )}
        {this.renderImportButton()}
      </ButtonRow>
    );
  }

  import = (e) => {
    const store = get(this.props, ["settings", "store"]);
    const paymentPlan = get(store, "payment_plan");

    // if (paymentPlan || store.id < 141387) {
    if (paymentPlan) {
      if (!this.props.product.imported) {
        if (this.props.minimized) {
          // Just import
          e.stopPropagation();
          this.props.importToStore(e);
        } else {
          // Save, then import
          this.props.saveProduct(true);
        }
      }
    } else {
      // NO PAYMENT PLAN
      e.stopPropagation();
      const upgradeMessage =
        "Please upgrade to a paid plan to import products. " +
        "Click here to see our plans!";

      createNotification({
        type: "info",
        title: "Upgrade Required",
        message: upgradeMessage,
        duration: 7000,
        route: "/settings/plans",
      });
    }
  };

  renderImportButton = () => {
    const { product } = this.props;

    const platform = get(this.props, [
      "settings",
      "store",
      "platform",
      "is_connected",
    ]);

    const specialReferrers = ["dropshipforsale", "ecompremiere"].includes(
      get(this.props, ["settings", "store", "referral_source"])
    );

    const privatePortal = get(this.props, [
      "settings",
      "store",
      "private_portal",
    ]);
    // Patch for NFT demo
    const location = window.location.toString();
    let isGlobal = false;
    if (location.includes("global") || location.includes("nft")) {
      isGlobal = true;
    }

    const privatePortalApproved =
      privatePortal && privatePortal.status === "APPROVED";

    if (
      platform &&
      (isGlobal || !privatePortal || privatePortalApproved || specialReferrers)
    ) {
      return (
        <StyledButton
          text={
            product.isFetching
              ? "IMPORTING..."
              : product.imported
              ? "IMPORTED"
              : "IMPORT"
          }
          disabled={product.imported}
          onClick={this.import}
        />
      );
    } else {
      return (
        <StyledButton
          text="CONNECT PLATFORM"
          onClick={() => this.props.history.push("/settings/platforms")}
        />
      );
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductButtons)
);
